import React, { useState } from "react";
import Loader from "../../Components/Loader/Loader";
import { fetchVehicle } from "../../Redux/Actions/Vehicles";
import { convertToMonthDayYear } from "../../utils/common";
import { Link } from "react-router-dom";
import "./Vehicle.scss";
import { useQuery, useIsFetching } from "react-query";

const Vehicle = (props) => {
  const isLoading = useIsFetching();
  const [vehicle, setVehicle] = useState({});

  useQuery(
    ["vehicle", props.match.params.id],
    async () => {
      return await fetchVehicle(props.match.params.id);
    },
    {
      onSuccess: (_data) => {
        const { data } = _data;
        data.activity_details = [...data.activity_details].sort((a, b) => {
          const sortDate = a.date.localeCompare(b.date);
          if (sortDate !== 0) {
            return sortDate;
          }
          // Sort by end_mileage (higher first)
          return a.end_mileage === b.end_mileage ? 1 : -1;
        });
        setVehicle(data);
      },
    }
  );

  const formatWorkOrder = (value = "") => {
    value = value ? value : "";
    const splittedValue = value.split("-");
    return splittedValue[splittedValue.length - 1];
  };

  return (
    <div className="activity-page__container vehicle-container">
      {isLoading ? <Loader /> : null}
      <Link to="/vehicles" className="vehicle-page__back-link btn btn-primary">
        Back
      </Link>
      <div className="activity_page-details">
        <div className="activity__left-content">
          <h6>
            {vehicle.year} {vehicle.vehicle_make} {vehicle.vehicle_model}
          </h6>
          <div className="activity__sub-details vehicle-details">
            <span className="capitalize ">VIN: {vehicle.vin}</span>
            <br />
            <span className="capitalize ">Plate: {vehicle.license_plate}</span>
            <br />
            <span>
              {vehicle.exterior_color} | {vehicle.engine_type}
            </span>
          </div>
        </div>
      </div>

      <div className="vehicle-page__activity-details-list">
        {vehicle.activity_details &&
          vehicle.activity_details
            .filter((item) => item.activity_type !== "Storage")
            .map((details, index) => {
              return (
                <div className="row detail-item" key={index}>
                  <div className="col-8">
                    <span>
                      {details.date
                        ? convertToMonthDayYear(details.date)
                        : details.date}
                    </span>
                    <br />
                    <span>
                      {details.activity_type} - {details.activity_detail_type}{" "}
                      {details.alternate_to && details.alternate_to.trim()
                        ? `- ${details.alternate_to}`
                        : ""}
                    </span>
                  </div>
                  <div className="col-4">
                    <Link to={`/activity/${details.id}`}>
                      <span className="vin">
                        {formatWorkOrder(details.work_order_number)}
                      </span>
                    </Link>
                    <br />
                    <span>
                      {details.start_mileage}{" "}
                      {details.end_mileage ? `- ${details.end_mileage}` : ""}
                    </span>
                  </div>
                </div>
              );
            })}
      </div>
    </div>
  );
};

export default Vehicle;
