import React, { useState } from "react";
import isEmpty from "lodash/isEmpty";
import { fetchContact, getMugshotImage } from "../../Redux/Actions/Contacts";
import Loader from "../../Components/Loader/Loader";
import { handleResponse } from "../../utils/Notification";
import { useHistory } from "react-router-dom";
import "./details.scss";
import { useQuery, useIsFetching } from "react-query";

const ContactDetails = ({
  contactDetails = {},
  resetContact = () => {},
  ...props
}) => {
  const [contact, setContact] = useState(contactDetails);
  const [contactImage, setContactImage] = useState("");
  const isLoading = useIsFetching();
  const history = useHistory();

  useQuery(
    ["fetchContact", props.match.params.id],
    async () => await fetchContact(props.match.params.id),
    {
      onSuccess: ({ data: offices }) => {
        setContact(offices.find((a) => a.id === props.match.params.id));
      },
      onError: (error) => handleResponse(error),
      initialData: [],
    }
  );

  useQuery(
    ["getMugshotImage", props.match.params.id],
    async () => await getMugshotImage(props.match.params.id),
    {
      onSuccess: ({ data: offices }) => setContactImage(offices),
      onError: (error) => handleResponse(error),
    }
  );

  // useEffect(() => {
  //   const fetchInitialData = async () => {
  //     const response = await fetchContact(props.match.params.id);
  //     const contactImageResponse = await getMugshotImage(props.match.params.id);
  //     if (response.success) {
  //       setContact(response.data.find((a) => a.id === props.match.params.id));
  //     }
  //     if (contactImageResponse.success) {
  //       setContactImage(contactImageResponse.data);
  //     }
  //     handleResponse(response);
  //   };
  //   fetchInitialData();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  const mapsSelector = (e, address = "") => {
    e.preventDefault();
    if (
      /* if we're on iOS, open in Apple Maps */
      navigator.platform.indexOf("iPhone") !== -1 ||
      navigator.platform.indexOf("iPad") !== -1 ||
      navigator.platform.indexOf("iPod") !== -1
    ) {
      window.open(`maps://maps.google.com/maps?q=${address}`);
    } else {
      /* else use Google */
      window.open(`https://maps.google.com/maps?q=${address}`);
    }
  };

  const formatNum = (value = "") => {
    return `${value.slice(0, 3)}-${value.slice(3, 6)}-${value.slice(6, 10)}`;
  };

  if (isEmpty(contact)) {
    return null;
  }
  return (
    <>
      {isLoading ? <Loader /> : null}
      <div className="activity-page__container">
        <div className="pl-2">
          <button
            type="button"
            onClick={(e) => history.push("/contacts")}
            className="vehicle-page__back-link btn btn-primary"
          >
            Back
          </button>
        </div>

        <div className="contact-details-page contactDetails">
          <div className="contact__left-content pl-3">
            <p className="page-title">{`${contact.attributes.first_name} ${contact.attributes.last_name}`}</p>
            <p className="sub-title">{contact.attributes.affiliation}</p>

            <div className="mb-3 mt-3">
              <h6 className="email">{contact.attributes.email}</h6>
            </div>

            {contact.attributes.phone_and_type.map((record, index) => {
              return Object.keys(record).map((key) => {
                return (
                  <div className="mb-3" key={index}>
                    {key}:{" "}
                    <span className="email">
                      <a href={`tel:${record[key]}`}>
                        {formatNum(record[key])}
                      </a>
                    </span>
                  </div>
                );
              });
            })}

            {contact.attributes &&
              contact.attributes.addresses_and_type &&
              contact.attributes.addresses_and_type.map((address, index) => {
                return (
                  <div className="row pl-3" key={index}>
                    <div className="mb-3">
                      <p className="mb-0">{address.type}</p>
                      <a
                        href="#"
                        onClick={(e) =>
                          mapsSelector(
                            e,
                            `${address.street1 || ""} ${
                              address.street2 || ""
                            } ${address.city || ""}, ${address.state || ""} ${
                              address.postal_code
                            }`
                          )
                        }
                      >
                        <span className="highlight">{`${
                          address.street1 || ""
                        } ${address.street2 || ""} ${address.city || ""}, ${
                          address.state || ""
                        } ${address.postal_code}`}</span>
                      </a>
                    </div>
                  </div>
                );
              })}
          </div>
          <div className="contact__left-right pr-4">
            {contactImage && (
              <img
                src={contactImage}
                height="100px"
                width="75px"
                objectFit="cover"
                alt="profile_img"
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactDetails;
