import React, { useState } from "react";
import Loader from "../../../Components/Loader/Loader";
import PercentageLoader from "../../../Components/PercentageLoader/Loader";
import { toast } from "react-toastify";
import {
  updateExpense,
  fetchActivityData,
  fetchExtraPreps,
  fetchActivityReceipts,
  fetchACtivityAttachments,
  fetchFeeTypes,
  uploadReceipt,
  uploadAttachment,
  deleteReceipts,
  deleteProcessingReceipts,
  updateActivity,
  fetchDrivers,
  fetchPaymentMethods,
  createExpense,
  deleteExpense,
  deleteAttachment,
  fetchUploadedReceipts,
} from "../../../Redux/Actions/Activity";
import { useDispatch } from "react-redux";
import { handleResponse } from "../../../utils/Notification";
import CostEditForm from "../../../Components/Expense/Form";
import mask from "../../../utils/mask";
import { convertToMonthDayYear, dateStringForURL } from "../../../utils/common";
import { Link, useHistory } from "react-router-dom";
import "./form.scss";
import Modal from "react-modal";
import FixedScrollHeader from "../FixedScrollHeader";
import TextareaAutosize from "react-textarea-autosize";
import { useQuery, useMutation } from "react-query";

const customModalStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

const updationKeys = [
  "incident_notes",
  "start_mileage",
  "end_mileage",
  "fueled",
  "detailed",
  "inspected",
  "extra_prep_type_id",
  "driver_id",
];

Modal.setAppElement("#root");

const Form = ({ ...props }) => {
  const [activityData, setActivityData] = useState({});
  const [costData, setCostData] = useState({ payment_method_id: 6 });
  const [DWObject, setDWObject] = useState(null);
  const [editACtivity, setEditActivity] = useState({});
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const history = useHistory();

  const queryParams = new URLSearchParams(window.location.search);
  const queryParamsSelectedOffice = queryParams.get("selected_office");
  const queryParamsSelectedDate = queryParams.get("selected_date");

  const dispatch = useDispatch();
  const attachmentRef = React.useRef();
  const receiptRef = React.useRef();

  // Get Activity Data
  const { refetch: refetchActivityData, isFetching: _isFetchingActivityData } =
    useQuery(
      ["activityData"],
      async (_key) => {
        return await fetchActivityData(props.match.params.id);
      },
      {
        onSuccess: ({ data }) => {
          setActivityData(data);
        },
        onError: (error) => {
          handleResponse(error);
        },
      }
    );

  // Get Preps Data
  const { data: preps, isFetching: _isFetchingPrepData } = useQuery(
    ["preps"],
    async (_key) => {
      return await fetchExtraPreps();
    },
    {
      initialData: [],
      onError: (error) => {
        handleResponse(error);
      },
    }
  );

  // Get Fee Data
  const {
    data: { data: feeTypes, isFetching: _isFetchingFeeData },
  } = useQuery(
    ["feeTypes"],
    async (_key) => {
      return fetchFeeTypes({ activity_id: activityData?.activity_id });
    },
    {
      enabled: !!activityData?.activity_id,
      initialData: { data: [] },
      onError: (error) => {
        handleResponse(error);
      },
    }
  );

  // Get Payment Methods Data
  const {
    data: { data: paymentMethods, isFetching: _isFetchingPaymentData },
  } = useQuery(
    ["paymentMethods"],
    async (_key) => {
      return await fetchPaymentMethods();
    },
    {
      initialData: { data: [] },
      onError: (error) => {
        handleResponse(error);
      },
    }
  );

  // Get Attachment Data
  const {
    data: { data: attachments, isFetching: _isFetchingAttachmentData },
    refetch: refetchAttachments,
  } = useQuery(
    ["attachments"],
    async (_key) => {
      return await fetchACtivityAttachments({
        activity_id: activityData?.activity_id,
      });
    },
    {
      enabled: !!activityData?.activity_id,
      initialData: { data: [] },
      onError: (error) => {
        handleResponse(error);
      },
    }
  );

  // Get Receipt Data
  const {
    data: { data: receipts, isFetching: _isFetchingReceiptData },
    refetch: refetchReceipts,
  } = useQuery(
    ["receipts"],
    async (_key) => {
      return await fetchActivityReceipts({
        activity_detail_id: activityData?.activity_detail_id, // TODO: This may be wrong?
      });
    },
    {
      enabled: !!activityData.activity_detail_id,
      initialData: { data: [] },
      onError: (error) => {
        handleResponse(error);
      },
    }
  );

  // Get Uploaded Receipt Data
  const {
    data: { data: uploadedReceipts, isFetching: _isFetchingUploadedReceipts },
    refetch: refetchUploadedReceipts,
  } = useQuery(
    ["uploadedReceipts"],
    async (_key) => {
      return await fetchUploadedReceipts({
        activity_detail_id: activityData?.activity_detail_id, // TODO: This may be wrong?
      });
    },
    {
      enabled: !!activityData.activity_detail_id,
      initialData: { data: [] },
      onError: (error) => {
        handleResponse(error);
      },
    }
  );

  // Get Driver Data
  const {
    data: { data: drivers, isFetching: _isFetchingDriverData },
  } = useQuery(
    ["drivers"],
    async (_key) => {
      return fetchDrivers(props.match.params.id);
    },
    {
      initialData: { data: [] },
      onError: (error) => {
        handleResponse(error);
      },
    }
  );

  const mapsSelector = (e, address = "") => {
    e.preventDefault();
    if (
      /* if we're on iOS, open in Apple Maps */
      navigator.platform.indexOf("iPhone") !== -1 ||
      navigator.platform.indexOf("iPad") !== -1 ||
      navigator.platform.indexOf("iPod") !== -1
    ) {
      window.open(`maps://maps.google.com/maps?q=${address}`);
    } else {
      /* else use Google */
      window.open(`https://maps.google.com/maps?q=${address}`);
    }
  };

  const handleImageShow = (e, url) => {
    window.open(url, "_blank");
  };

  const setModalOpen = (type, item) => {
    setModalIsOpen(true);
    setItemToDelete({ type, item });
  };

  const onConfirmDeleteClicked = () => {
    // Handles deleting receipts, attachments, and expenses.
    handleDelete(itemToDelete);
    setItemToDelete(null);
    setModalIsOpen(false);
  };

  const resetSelectedActivity = () => {
    // Triggered after editing an expense item.
    setEditActivity({});
    refetchActivityData();
  };

  // Submit Cost
  const { mutate: submitCost } = useMutation(
    async (e) => {
      e.preventDefault();
      if (costData.fee_type_id && costData.cost && costData.payment_method_id) {
        const data = {
          activity_item_cost: {
            fee_type_id: costData.fee_type_id,
            incurred_amount_or_hours: costData.cost,
            payment_method_id: costData.payment_method_id,
          },
        };
        return await createExpense(data, activityData?.id);
      }
    },
    {
      onSuccess: () => {
        refetchActivityData();
        setCostData({ payment_method_id: 6 });
      },
      onError: () => {
        handleResponse("Please fill all expense details.");
      },
    }
  );

  // Upload Receipt
  const { mutate: handleReceiptUpload } = useMutation(
    async (e) => {
      e.preventDefault();
      if (e.target.files[0]) {
        let formdata = new FormData();
        formdata.append("activity_detail_id", activityData?.id);
        formdata.append("uploaded_data", e.target.files[0]);
        return await uploadReceipt(formdata, { setUploadProgress });
      }
    },
    {
      onSuccess: () => {
        refetchReceipts();
        refetchUploadedReceipts();
        setUploadProgress(null);
      },
      onError: (error) => {
        handleResponse(error);
        setUploadProgress(null);
      },
    }
  );

  // Upload attachment
  const { mutate: handleAttachmentChange } = useMutation(
    async (e) => {
      e.preventDefault();
      let attachment;
      if (e.target.files.length > 0) {
        attachment = e.target.files[0];
      }

      if (attachment) {
        let formdata = new FormData();
        formdata.append("activity_id", activityData?.activity_id);
        formdata.append("uploaded_data", attachment);
        return await uploadAttachment(formdata, {
          setUploadProgress,
        });
      }
    },
    {
      onSuccess: () => {
        refetchAttachments();
        setUploadProgress(null);
      },
      onError: () => {
        handleResponse("Please select an attachment to upload.");
        setUploadProgress(null);
      },
    }
  );

  const handleCostChange = (e) => {
    const { value, name } = e.target;
    setCostData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  // Delete Expense
  const { mutate: _deleteExpense } = useMutation(
    async (item) => {
      return await deleteExpense(activityData.id, item.id);
    },
    {
      onSuccess: () => {
        refetchActivityData(props.match.params.id);
        attachmentRef.current.value = "";
      },
      onError: (error) => {
        handleResponse(error);
      },
    }
  );

  // Delete Receipt
  const { mutate: _deleteReceipt, isLoading: isDeleteReceiptLoading } =
    useMutation(
      async (item) => {
        if (item?.status === "processing") {
          return await deleteProcessingReceipts(item.id);
        }
        return await deleteReceipts(item.id);
      },
      {
        onSuccess: () => {
          refetchReceipts();
          refetchUploadedReceipts();
          receiptRef.current.value = "";
        },
        onError: (error) => {
          handleResponse(error);
        },
      }
    );

  // Delete Attachment
  const { mutate: _deleteAttachment, isLoading: isDeleteAttachmentLoading } =
    useMutation(
      async (item) => {
        return await deleteAttachment(item.id);
      },
      {
        onSuccess: () => {
          refetchAttachments();
          attachmentRef.current.value = "";
        },
        onError: (error) => {
          handleResponse(error);
        },
      }
    );

  const handleDelete = async ({ type, item }) => {
    if (type === "expense") {
      _deleteExpense(item);
    }
    if (type === "receipt") {
      _deleteReceipt(item);
    } else if (type === "attachment") {
      _deleteAttachment(item);
    }
  };

  const handleChange = (e) => {
    const { type, value, checked } = e.target;
    setActivityData((prevState) => ({
      ...prevState,
      [e.target.name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleFormSubmit = async (e, isStayOnPage = false) => {
    e.preventDefault();
    const response = await updateActivity(
      {
        activity_detail: mask(activityData, updationKeys),
      },
      activityData?.id
    );
    handleResponse(response);

    if (response.success) {
      toast.success("Activity Updated!");
      if (!isStayOnPage) {
        let searchParams = "";
        if (queryParamsSelectedOffice && queryParamsSelectedDate) {
          searchParams = `?selected_office=${queryParamsSelectedOffice}&selected_date=${dateStringForURL(queryParamsSelectedDate)}`;
        } else if (queryParamsSelectedOffice) {
          searchParams = `?selected_office=${queryParamsSelectedOffice}`;
        } else if (queryParamsSelectedDate) {
          searchParams = `?selected_date=${dateStringForURL(queryParamsSelectedDate)}`;
        }
        history.push({
          pathname: "/",
          ...(searchParams && {
            search: searchParams,
          }),
        });
      }
    }
  };

  const handleClick = (e) => {
    e.preventDefault();
    DWObject.AcquireImage();
  };

  const handleExpenseUpdate = async (data) => {
    const response = await dispatch(
      updateExpense(
        {
          fee_type_id: data.fee_type_id,
          payment_method_id: data.payment_method_id,
          incurred_amount_or_hours: data.cost,
        },
        activityData?.id,
        editACtivity.id
      )
    );

    if (response.success) {
      setEditActivity({});
      refetchActivityData();
    }
    handleResponse(response);
  };

  const handleSave = (e, isStayOnPage = false) => {
    if (editACtivity.id) {
      resetSelectedActivity();
    } else {
      handleFormSubmit(e, isStayOnPage);
    }
  };

  const handleCompletedByOnChange = (e) => {
    const value = e.target.value;
    setActivityData((prevState) => ({
      ...prevState,
      driver_id: parseInt(value),
    }));
  };

  const convertToMonthDayYearWithTime = (date) => {
    const dateObj = new Date(date);
    var month = dateObj.getUTCMonth() + 1; //months from 1-12
    var day = dateObj.getUTCDate();
    var year = dateObj.getUTCFullYear();
    var hours = dateObj.getHours();
    var minutes = dateObj.getMinutes();
    var ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? "0" + minutes : minutes;
    var strTime = hours + ":" + minutes + " " + ampm;
    return `${month}/${day}/${year} ${strTime}`;
  };

  const completedByOptions = () => {
    const currentId = activityData?.driver_id;
    const currentIdInDrivers = drivers.find(
      (driver) => driver.id === currentId
    );
    if (currentIdInDrivers) {
      const filteredDrivers = drivers.map((driver) => {
        if (driver.id === currentIdInDrivers.id) {
          return (
            <option
              selected
              value={driver.id}
            >{`${driver.last_name} ${driver.first_name}`}</option>
          );
        }
        return (
          <option
            value={driver.id}
          >{`${driver.last_name} ${driver.first_name}`}</option>
        );
      });
      filteredDrivers.unshift(<option value=""></option>);
      return filteredDrivers;
    }
    return [
      <option value=""></option>,
      ...drivers.map((driver) => (
        <option
          value={driver.id}
        >{`${driver.last_name} ${driver.first_name}`}</option>
      )),
    ];
  };

  const isLoading =
    _isFetchingDriverData ||
    _isFetchingReceiptData ||
    _isFetchingAttachmentData ||
    _isFetchingPaymentData ||
    _isFetchingPrepData ||
    _isFetchingFeeData ||
    _isFetchingActivityData;

  const receiptsToDisplay = [...receipts, ...uploadedReceipts];

  return (
    <div id="activityForm" className="activity-page-edit__container">
      {isLoading || isDeleteReceiptLoading || isDeleteAttachmentLoading ? (
        <Loader />
      ) : null}
      {uploadProgress ? <PercentageLoader percentage={uploadProgress} /> : null}
      <FixedScrollHeader editACtivity={editACtivity} handleSave={handleSave} />
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        style={customModalStyles}
        contentLabel="Example Modal"
      >
        <h2>Confirmation</h2>
        <div>
          Are you sure you would like to delete this{" "}
          {itemToDelete?.type || "item"}?
        </div>
        <div
          style={{
            marginTop: "20px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <button
            className="btn btn-secondary"
            style={{ marginRight: "25px" }}
            onClick={() => setModalIsOpen(false)}
          >
            Close
          </button>
          <button
            className="btn btn-success"
            style={{ marginLeft: "25px" }}
            onClick={() => onConfirmDeleteClicked()}
          >
            Confirm
          </button>
        </div>
      </Modal>
      <div className="container mt-2">
        <div className="row">
          <div className="col">
            <span>
              <b>{activityData?.work_order_number}</b>
            </span>
            <br />
            <span>
              <a href={`/vehicle/${activityData?.vehicle_id}`}>
                <b>VIN: {activityData?.vin}</b>
              </a>
            </span>
            <br />
            <span>
              <b>Rego: {activityData?.plate_name}</b>
            </span>
          </div>
        </div>
      </div>
      {editACtivity.id ? (
        <CostEditForm
          feeTypes={feeTypes}
          data={editACtivity}
          paymentMethods={paymentMethods}
          handleExpenseUpdate={handleExpenseUpdate}
          resetSelectedActivity={resetSelectedActivity}
        />
      ) : (
        <form
          className="activity-page-edit__form"
          onSubmit={(e) => handleFormSubmit(e)}
        >
          <div className="container mt-2">
            <div className="row">
              <div className="col">
                <p>{activityData?.vehicle_make_model}</p>
                <p>
                  {activityData?.activity_type} |{" "}
                  {activityData?.activity_detail_type}
                </p>
                <span>Address:</span>
                <br />
                <a
                  href="#"
                  onClick={(e) =>
                    mapsSelector(
                      e,
                      `${activityData?.street1 || ""} ${
                        activityData?.state_abbrev || ""
                      } ${activityData?.city || ""}`
                    )
                  }
                >
                  <span>
                    {activityData?.street1}
                    <br /> {activityData?.city}{" "}
                    {activityData?.state_abbrev &&
                    activityData?.state_abbrev !== "--"
                      ? `, ${activityData?.state_abbrev}`
                      : ""}
                  </span>
                </a>
              </div>

              <div className="col ">
                {activityData?.contact_id ? (
                  <Link
                    className="alternate-to"
                    to={`/contact/${activityData?.contact_id}`}
                  >
                    {activityData?.alternate_to || ""}
                  </Link>
                ) : (
                  <p>{activityData?.alternate_to || ""}</p>
                )}
                <p className="loan-reason">{activityData?.loan_reason || ""}</p>
                <br />
              </div>
            </div>

            <div className="row mt-3">
              <div className="col">Delivery Notes:</div>
            </div>

            <div className="row">
              <div className="col">
                <TextareaAutosize
                  style={{
                    width: "100%",
                    overflow: "hidden",
                    resize: "none",
                    padding: "9px",
                    boxSizing: "border-box",
                  }}
                  value={activityData?.notes}
                  name="notes"
                  disabled
                />
              </div>
            </div>
            <div>
              <div className="row">
                <div className="col">
                  Date: {convertToMonthDayYear(activityData?.date)}
                </div>
              </div>
              <div className="row">
                <div className="col-4">
                  <label htmlFor="completed_by">Completed By:</label>
                </div>
                <div className="col-8" style={{ height: "60px" }}>
                  <select
                    onChange={handleCompletedByOnChange}
                    name="completed_by"
                    style={{ width: "220px", marginBottom: "10px" }}
                  >
                    {completedByOptions()}
                  </select>
                </div>
              </div>

              <div className="row">
                <div className="col-4">
                  <label htmlFor="start_mileage">Start KM:</label>
                </div>
                <div className="col-8">
                  <input
                    type="number"
                    name="start_mileage"
                    value={activityData?.start_mileage}
                    onChange={handleChange}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-4">
                  <label htmlFor="end_mileage">End KM:</label>
                </div>
                <div className="col-8">
                  <input
                    type="number"
                    name="end_mileage"
                    value={activityData?.end_mileage}
                    onChange={handleChange}
                  />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col">
                <label htmlFor="extra_prep_type_id">Extra Prep:</label>
                <select
                  name="extra_prep_type_id"
                  onChange={(e) => handleChange(e)}
                  value={activityData?.extra_prep_type_id}
                >
                  {preps?.data?.map((prep) => {
                    return (
                      <option key={prep.id} value={prep.id}>
                        {prep.name}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>

            <div className="row" style={{ margin: "35px 0" }}>
              <div className="col" style={{ padding: 0 }}>
                <div className="form-check form-check-inline">
                  <div className="form-check-label" for="fueled">
                    Fueled:
                  </div>
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="fueled"
                    value="fueled"
                    name="fueled"
                    onChange={handleChange}
                    checked={activityData?.fueled}
                  />
                </div>
              </div>
              <div className="col">
                <div className="form-check form-check-inline">
                  <div className="form-check-label" for="detailed">
                    Detailed:
                  </div>
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="detailed"
                    value="detailed"
                    name="detailed"
                    onChange={handleChange}
                    checked={activityData?.detailed}
                  />
                </div>
              </div>
              <div className="col">
                <div className="form-check form-check-inline">
                  <div className="form-check-label" for="inspected">
                    Inspected:
                  </div>
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="inspected"
                    value="inspected"
                    name="inspected"
                    onChange={handleChange}
                    checked={activityData?.inspected}
                  />
                </div>
              </div>
            </div>
            <div className="row" hidden={activityData?.is_closed}>
              <div className="col expense-field">
                <span>Expenses Type</span>
                <br />

                <select
                  key={
                    activityData?.activity_item_costs
                      ? activityData?.activity_item_costs.length
                      : 0
                  }
                  name="fee_type_id"
                  onChange={(e) => handleCostChange(e)}
                  value={costData.fee_type_id}
                >
                  <option>Select item.</option>
                  {feeTypes.map((prep) => {
                    return (
                      <option key={prep.id} value={prep.id}>
                        {prep.name}
                      </option>
                    );
                  })}
                </select>
              </div>

              <div className="col cost-field">
                <span>Cost</span>
                <br />
                <input
                  key={
                    activityData?.activity_item_costs
                      ? activityData?.activity_item_costs.length
                      : 0
                  }
                  type="number"
                  onChange={(e) => handleCostChange(e)}
                  name="cost"
                  value={costData.cost}
                  style={{ height: "25px" }}
                />
                <button
                  onClick={(e) => submitCost(e)}
                  className="btn btn-success ml-1"
                >
                  +
                </button>
              </div>
            </div>

            <table className="table">
              <thead className="thead-light">
                <tr>
                  <th scope="col">Type</th>
                  <th scope="col">Cost</th>
                  <th scope="col">Paid By</th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                {activityData?.activity_item_costs ? (
                  activityData?.activity_item_costs.map((item) => {
                    return (
                      <tr key={item.id}>
                        <td
                          className="highlight"
                          scope="row"
                          onClick={(e) => setEditActivity(item)}
                        >
                          {item.fee_type}
                        </td>
                        <td>{item.cost}</td>
                        <td>{item.payment_method}</td>
                        <td>
                          <button
                            hidden={activityData?.is_closed}
                            onClick={() => setModalOpen("expense", item)}
                            type="button"
                            class="btn btn-danger"
                          >
                            -
                          </button>
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <th colSpan="4" scope="row">
                      No records found.
                    </th>
                  </tr>
                )}
              </tbody>
            </table>

            <div className="row">
              <div className="col expense-field">
                <span>Receipts</span>
                <br />
                <div class="file-input btn btn-success">
                  Add Receipt
                  <input
                    type="file"
                    className="receipt-upload"
                    accept="image/x-png,image/gif,image/jpeg"
                    onChange={(e) => handleReceiptUpload(e)}
                    ref={receiptRef}
                  />
                </div>
                <a
                  onClick={(e) => handleClick(e)}
                  className="mt-2 btn btn-success ml-1 d-none"
                >
                  Add Receipt
                </a>
              </div>
            </div>

            {receiptsToDisplay.map((receipt, index) => {
              const receiptUrl = `${process.env.REACT_APP_DRIVESHOP_URL}${receipt.public_filename}`;
              return (
                <div className="row mt-2">
                  <div className="col" key={index}>
                    <img
                      height="100"
                      width="100"
                      onClick={(e) => handleImageShow(e, receiptUrl)}
                      src={receiptUrl}
                    />
                  </div>
                  <div className="col right">
                    <a
                      onClick={() => setModalOpen("receipt", receipt)}
                      className="btn btn-primary"
                    >
                      x
                    </a>
                  </div>
                </div>
              );
            })}

            <div className="row mt-2">
              <div className="col">
                <span>Damage / Driver Notes</span>
                <br />
              </div>

              <div className="col d-none">
                <div
                  className="btn-group btn-group-toggle"
                  data-toggle="buttons"
                >
                  <label className="btn btn-secondary active">
                    <input
                      type="radio"
                      name="options"
                      id="option1"
                      checked={activityData?.incident}
                    />{" "}
                    Yes
                  </label>
                  <label className="btn btn-secondary">
                    <input
                      type="radio"
                      name="options"
                      id="option2"
                      checked={!activityData?.incident}
                    />{" "}
                    No
                  </label>
                </div>
              </div>
            </div>

            <div className="row mt-2">
              <div className="col">
                <textarea
                  rows="5"
                  value={activityData?.incident_notes}
                  name="incident_notes"
                  onInput={handleChange}
                />
              </div>
            </div>

            <div className="row mt-2 mb-3">
              <div className="col expense-field">
                <span>Attachments/Photos</span>
                <br />
                <label class="file-input btn btn-success">
                  Add File
                  <input
                    type="file"
                    onChange={(e) => handleAttachmentChange(e)}
                    ref={attachmentRef}
                  />
                </label>
              </div>
            </div>

            {attachments.map((attachmentRecord, index) => {
              const attachmentUrl = `${process.env.REACT_APP_DRIVESHOP_URL}/${attachmentRecord.public_filename}`;
              return (
                <div className="row mt-2">
                  <div className="col" key={index}>
                    <img
                      alt={attachmentRecord.id}
                      height="100"
                      width="100"
                      onClick={(e) => handleImageShow(e, attachmentUrl)}
                      src={attachmentUrl}
                    />
                  </div>
                  <div className="col right">
                    <a
                      onClick={() =>
                        setModalOpen("attachment", attachmentRecord)
                      }
                      className="btn btn-primary"
                    >
                      x
                    </a>
                  </div>
                </div>
              );
            })}
          </div>
        </form>
      )}
      <div style={{ textAlign: "center", fontSize: "12px" }}>
        {`Updated at: ${convertToMonthDayYearWithTime(
          activityData?.activity_updated_at
        )} by ${activityData?.activity_updated_by}`}
      </div>
    </div>
  );
};

export default Form;
